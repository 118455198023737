<template>
  <div>
    <AppMenu />

    <!-- m="4" md="4" lg="4" -->
    <div tabindex="0">
      <v-row no-gutters class="mt-4 pa-3">
        <v-col cols="12" class="pa-3">
          <v-row>
            <v-col cols="12" sm="12" md="6" lg="3" class="mb-4">
              <v-card
                class="white--text text-center pa-4"
                color="primary"
                to="/pos"
                :title="$t('go_to_pos')"
              >
                <v-card-title class="justify-center font-weight-regular">{{
                  $t("go_to_pos")
                }}</v-card-title>
                <v-card-text>
                  <v-icon dark size="80">mdi-cast-variant</v-icon>
                </v-card-text>
              </v-card>
            </v-col>

            <v-col cols="12" sm="12" md="6" lg="3" class="mb-4">
              <v-card
                class="white--text text-center pa-4"
                color="primary"
                @click="uploadSyncro()"
                :title="$t('upload_sync')"
              >
                <v-card-title class="justify-center font-weight-regular">{{
                  $t("upload_sync")
                }}</v-card-title>
                <v-card-text>
                  <v-icon dark size="80">mdi-cloud-upload-outline</v-icon>
                </v-card-text>
              </v-card>
            </v-col>

            <v-col cols="12" sm="12" md="6" lg="3" class="mb-4">
              <v-card
                class="white--text text-center pa-4"
                color="primary"
                @click="downloadSyncro()"
                :title="$t('download_sync')"
              >
                <v-card-title class="justify-center font-weight-regular">{{
                  $t("download_sync")
                }}</v-card-title>
                <v-card-text>
                  <v-icon dark size="80">mdi-cloud-sync-outline</v-icon>
                </v-card-text>
              </v-card>
            </v-col>

            <!--v-col
              cols="12"
              sm="12"
              md="6"
              lg="3"
              class="mb-4"
              v-if="$store.getters.getGeneralValue('gen_sales_manage_tables')"
            >
              <v-card
                class="white--text text-center pa-4"
                color="green"
                @click="emptyTables()"
                :title="$t('empty_tables')"
              >
                <v-card-title class="justify-center">{{
                  $t("empty_tables")
                }}</v-card-title>
                <v-card-text>
                  <v-icon dark size="80">mdi-table-furniture</v-icon>
                </v-card-text>
              </v-card>
            </v-col-->

            <!--v-col
              cols="12"
              sm="12"
              md="6"
              lg="3"
              class="mb-4"
              v-if="$store.getters.getPosValue('pos_active_clean_tool')"
            >
              <v-card
                class="white--text text-center pa-4"
                color="green"
                @click="cleanTool()"
                :title="$t('clean_system')"
              >
                <v-card-title class="justify-center">{{
                  $t("clean_system")
                }}</v-card-title>
                <v-card-text>
                  <v-icon dark size="80">mdi-broom</v-icon>
                </v-card-text>
              </v-card>
            </v-col-->
          </v-row>
        </v-col>
      </v-row>
    </div>
  </div>
</template>
<script>

import AppMenu from "../menu/AppMenu.vue";

export default {
  name: "Tools",
  components: { AppMenu },
  data: () => ({
    app_version: null,
    title: "Herramientas",

    //cart: null
    //priceList:
  }),

  mounted() {
    this.load();
  },
  methods: {
    openHelp() {
      window.open(process.env.VUE_APP_BASECONFIG_URL_HELP, "_blank");
    },
    launchMenuPos(evt) {
      return false;
    },
    async load() {
      setTimeout(async () => {
        this.app_version = process.env.VUE_APP_BASECONFIG_APP_VERSION;
        await this.$db.load();
        const installed = await this.$db.itsInstalled();
      }, 50);
    },
    toogleDrawer() {
      this.$store.commit("toggle");
    },
    async downloadSyncro() {
      await this.$pos.syncroRemoteToLocal();
    },
    async uploadSyncro() {
      await this.$pos.sincronizeSync();
    },
    async emptyTables() {
      await this.$pos.emptyTables();
    },
    async cleanTool() {
      await this.$pos.cleanLocalStorage();
    },
  },
};
</script>
